import { Observable } from "rxjs";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  Create,
  OpenInNew,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { formatText, parsePhone } from "../../helpers/FormatUtils";
import {
  isValidObjectId,
  validateEmailLocalisation,
  validateRoleAuthorities,
  validateString,
} from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import {
  ROLE_ADMIN,
  ROLE_CUSTOMER,
  ROLE_RADAR_VIEWER,
  ROLE_USER,
} from "../../constants/AdminRoleTypes";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormWarehouseChips from "../form/FormWarehouseChips";
import FormCheckbox from "../form/FormCheckbox";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { hasUserPermission } from "../../reducers/ProfileReducer";
import { red } from "@material-ui/core/colors";

const valueSelector = formValueSelector("UserForm");

const Statuses = OrderedSet([ACTIVE, INACTIVE]);

const enhancer = compose(
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  connect(state => ({
    hasAVVALPermission: hasUserPermission(state, "AVVAL_USER_CONNECTOR"),
    supplier: valueSelector(state, "supplier"),
    status: valueSelector(state, "status"),
    roles: valueSelector(state, "roles"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "UserForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      email: validateEmailLocalisation(
        values.email,
        props.getLocalisationMessage,
      ),
      first_name: validateString(
        values.first_name,
        props.getLocalisationMessage("this_field_is_required"),
      ),
      last_name: validateString(
        values.last_name,
        props.getLocalisationMessage("this_field_is_required"),
      ),
      roles:
        validateRoleAuthorities(values.roles) &&
        props.getLocalisationMessage("role_is_required", "Role is required"),
      warehouse: !isValidObjectId(values.warehouse) && "Select Warehouse",
      // password: validatePasswordForLocalisation(
      //   values.password,
      //   props.getLocalisationMessage,
      // ),
    }),
  }),
  mapPropsStream(propsStream => {
    const targetOriginVerticalStream = propsStream
      .distinctUntilKeyChanged("roles", isEqualData)
      .map(() => {
        const cardText = document.getElementById("CardContentId");

        if (cardText && cardText.offsetHeight > 400) {
          return "bottom";
        }

        return "top";
      })
      .distinctUntilChanged()
      .startWith("top");

    const sideEffectsStream = Observable.merge(
      propsStream.distinctUntilKeyChanged("roles", isEqualData).do(props => {
        if (fp.includes(ROLE_ADMIN, props.roles)) {
          const generatedRoles = fp.union(props.roles, [
            ROLE_CUSTOMER,
            ROLE_USER,
            ROLE_RADAR_VIEWER,
          ]);
          props.change("roles", generatedRoles);
        }
      }),
    )
      .mapTo(null)
      .startWith(null)
      .distinctUntilChanged();

    return propsStream.combineLatest(
      targetOriginVerticalStream,
      sideEffectsStream,
      (props, targetOriginVertical) => ({
        ...props,
        targetOriginVertical,
      }),
    );
  }),
);
const url = "http://avval.uz/App/avval_info.php";
const username = "logistika";
const password = "1sdWq12ds!sA74";
const UserForm = ({
  dirty,
  disableEmail,
  getLocalisationMessage,
  handleSubmit,
  onCreateSupplierClick,
  onDismiss,
  onSupplierClick,
  reset,
  submitting,
  supplier,
  targetOriginVertical,
  userRoles,
  isEdit,
  initialValues,
  hasAVVALPermission,
}) => {
  const userPostcode = _.get(initialValues, "postcode.name");
  const [isLoadUsers, setIsLoadUsers] = useState(true);
  const [usersAVVAL, setUsersAVVAL] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (hasAVVALPermission && isEdit && userPostcode) {
      setIsLoadUsers(true);
      fetch(url, {
        mode: "cors",
        method: "post",
        headers: new Headers({
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        }),
        body: JSON.stringify({ avval_users: { post_code: userPostcode } }),
      })
        .then(response => response.json())
        .then(res => {
          const tempAvvalUsers = _.get(res, "list", []).filter(x =>
            _.get(x, "u_name"),
          );
          if (tempAvvalUsers.length > 0) {
            setUsersAVVAL(tempAvvalUsers);
          }
        })
        .catch(error => showErrorMessage(error))
        .finally(() => setIsLoadUsers(false));
    }
  }, [userPostcode]);
  return (
    <form autoComplete="off">
      <PageLoading isLoading={submitting} />
      <Card>
        <CardContent>
          <FlexBox gutter={8}>
            <FlexBox flex={true} direction="column">
              <Card>
                <CardContent>
                  <FormTextField
                    name="email"
                    margin="normal"
                    fullWidth={true}
                    readOnly={disableEmail}
                    label={`${getLocalisationMessage("email", "Email")} *`}
                  />
                  <FormTextField
                    name="first_name"
                    margin="normal"
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "first_name",
                      "First Name",
                    )} *`}
                  />
                  <FormTextField
                    name="last_name"
                    margin="normal"
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "last_name",
                      "Last Name",
                    )} *`}
                  />
                  <FormTextField
                    fullWidth={true}
                    name="phone"
                    margin="normal"
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )}
                    parse={parsePhone}
                  />
                  <FormCheckbox
                    name="is_using_scale"
                    margin="normal"
                    label={getLocalisationMessage("using_scale")}
                  />
                </CardContent>
              </Card>
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <Card>
                <CardContent>
                  <FlexBox>
                    <FlexBox flex={true}>
                      <FormSupplierAutoComplete
                        name="supplier"
                        margin="normal"
                        fullWidth={true}
                        label={getLocalisationMessage("supplier", "Supplier")}
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                      />
                    </FlexBox>

                    {isValidObjectId(supplier)
                      ? Boolean(onSupplierClick) && (
                          <FlexBox align="center">
                            <Tooltip
                              title={getLocalisationMessage(
                                "edit_supplier",
                                "Edit Supplier",
                              )}
                            >
                              <IconButton
                                onClick={() => onSupplierClick(supplier.id)}
                              >
                                <OpenInNew />
                              </IconButton>
                            </Tooltip>
                          </FlexBox>
                        )
                      : Boolean(onCreateSupplierClick) && (
                          <FlexBox align="center">
                            <Tooltip
                              title={getLocalisationMessage(
                                "create_supplier",
                                "Create Supplier",
                              )}
                            >
                              <IconButton onClick={onCreateSupplierClick}>
                                <Create />
                              </IconButton>
                            </Tooltip>
                          </FlexBox>
                        )}
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormWarehouseAutoComplete
                      name="warehouse"
                      margin="normal"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "primary_warehouse",
                        "Primary Warehouse",
                      )}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormWarehouseChips
                      name="warehouses"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "additional_warehouses",
                        "Additional Warehouses",
                      )}
                      hintText={getLocalisationMessage(
                        "type_here_to_search",
                        "Type Here To Search",
                      )}
                      margin="normal"
                    />
                  </FlexBox>

                  <FormSelectField
                    name="status"
                    margin="normal"
                    size="small"
                    autoWidth={true}
                    fullWidth={true}
                    options={Statuses}
                    formatOption={x =>
                      getLocalisationMessage(x) || formatText(x)
                    }
                    label={`${getLocalisationMessage("status", "Status")} *`}
                  />
                  <FormTextField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "password",
                      "Password",
                    )} *`}
                    withOutInputProps={false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </CardContent>
              </Card>
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <Card>
                <CardContent id="CardTextId">
                  <FormChipAutoComplete
                    maxHeight={150}
                    popoverProps={{
                      targetOrigin: {
                        vertical: targetOriginVertical,
                        horizontal: "left",
                      },
                    }}
                    name="roles"
                    margin="normal"
                    fullWidth={true}
                    options={userRoles}
                    formatOption={option =>
                      _.toUpper(
                        getLocalisationMessage(
                          _.isString(option) ? option : option.name,
                        ) ||
                          formatText(_.isString(option) ? option : option.name),
                      )
                    }
                    label={`${getLocalisationMessage("role", "Role")} *`}
                    chipContainerStyle={{
                      overflowY: "auto",
                    }}
                  />
                </CardContent>
              </Card>
            </FlexBox>
            {hasAVVALPermission && isEdit && userPostcode && (
              <FlexBox flex={true} direction="column">
                <Card>
                  <CardContent>
                    <FlexBox>
                      <FormSelectField
                        style={{ minWidth: 240 }}
                        loading={isLoadUsers}
                        disabled={usersAVVAL.length === 0}
                        name="avval_user"
                        fullWidth={true}
                        label={getLocalisationMessage("avval_users")}
                        withSuccessIcon={true}
                        options={usersAVVAL}
                        formatOption={v => _.get(v, "u_name")}
                        compareOptions={isEqualDataIn("uid")}
                      />
                    </FlexBox>
                    {usersAVVAL.length === 0 && (
                      <strong style={{ color: red[500] }}>
                        {getLocalisationMessage("avval_users_not_found")}
                      </strong>
                    )}
                  </CardContent>
                </Card>
              </FlexBox>
            )}
          </FlexBox>
        </CardContent>

        <FlexBox justify="flex-end">
          <CardActions>
            {dirty ? (
              <Button onClick={reset}>
                {getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(onDismiss) && (
                <Button onClick={onDismiss}>
                  {getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}

            <Button onClick={handleSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </CardActions>
        </FlexBox>
      </Card>
    </form>
  );
};

UserForm.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  userRoles: PropTypes.instanceOf(OrderedSet),
  supplier: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  disableEmail: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSupplierClick: PropTypes.func,
  onCreateSupplierClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  targetOriginVertical: PropTypes.oneOf(["top", "bottom"]),
  isEdit: PropTypes.bool,
  initialValues: PropTypes.func,
  change: PropTypes.func,
  hasAVVALPermission: PropTypes.func,
};

export default enhancer(UserForm);
